import React, { Component } from 'react'
import { Hidden } from 'react-grid-system'
import { Translate, translate } from 'react-i18nify'
import AlertBox from '../../Alert/AlertBox'
import Button from '../../Button/Button'

export default class CvUpload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            manualCv: false,
            fileUpload: false,
            file: null,
            error: false,
        }
    }

    save() {
        this.props.handleStopLoading()
        // If chosen to manually fill data, proceed to next step with no scan
        if (this.state.manualCv) {
            this.props.handleCvUpload(null)
            this.props.nextStep()
            return
        }

        // If chosen to scan CV, go to file upload flow
        if (this.state.fileUpload) {
            // If no file uploaded display error
            if (this.state.file == null) {
                this.setState({ error: true })
                return
            }

            // If file is uploaded, send to CV scanner
            this.setState({ loading: true })
            this.props.handleCvUpload(this.state.file)
        } else {
            this.setState({ fileUpload: true })
        }
    }

    back() {
        // reset the CV error
        this.props.handleErrorMessageReset()
        // set laoding to false
        this.setState({ loading: false })
        if (this.state.fileUpload) {
            this.setState({ fileUpload: false })
        } else {
            this.props.prevStep()
        }
        this.setState({ error: false })
    }

    handleChange(manual) {
        this.setState({ manualCv: manual })
    }

    handleFileChange(event) {
        this.setState({ file: event.target.files[0] })
        this.setState({ error: false })
        this.props.handleErrorMessageReset()
    }

    removeFile() {
        this.setState({ file: null })
    }

    render() {
        return (
            <div className="card--simple">
                <div className="onboarding__content">
                    <h1>
                        <p>
                            <Translate value="onboarding.cv.title" />
                        </p>
                    </h1>

                    {!this.state.fileUpload && (
                        <div>
                            <Hidden xs sm>
                                <p>
                                    <Translate value="onboarding.cv.text" />
                                </p>
                            </Hidden>

                            <div className="form__item">
                                <div className="cv__radio">
                                    <div className="form__radio">
                                        <input
                                            type="radio"
                                            name="radio_styled"
                                            id="study_graduated_yes"
                                            className="form__radio__input"
                                            checked={!this.state.manualCv}
                                            onChange={() => this.setState({ manualCv: false })}
                                            value="1"
                                            tabIndex="6"
                                        />
                                        <label
                                            className="form__radio__label"
                                            htmlFor="study_graduated_yes"
                                        >
                                            <Translate value="onboarding.cv.automatic.label" />
                                        </label>
                                        <p className="radio__details">
                                            <Translate value="onboarding.cv.automatic.text" />
                                        </p>
                                    </div>
                                    <div className="form__radio">
                                        <input
                                            type="radio"
                                            name="radio_styled"
                                            id="study_graduated_no"
                                            className="form__radio__input"
                                            checked={this.state.manualCv}
                                            onChange={() => this.setState({ manualCv: true })}
                                            value="0"
                                            tabIndex="7"
                                        />
                                        <label
                                            className="form__radio__label"
                                            htmlFor="study_graduated_no"
                                        >
                                            <Translate value="onboarding.cv.manual.label" />
                                        </label>
                                        <p className="radio__details">
                                            <Translate value="onboarding.cv.manual.text" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.fileUpload && (
                        <div className="cv__upload">
                            <div className="form__item">
                                <label htmlFor="cv" className="cv">
                                    <div className="heading">
                                        <Translate value="onboarding.cv.file.heading" />
                                    </div>
                                    <div>
                                        <Translate value="onboarding.cv.file.body" />
                                    </div>
                                    <div className="link">
                                        <Translate value="onboarding.cv.file.link" />
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    id="cv"
                                    name="cv"
                                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    onChange={(event) => this.handleFileChange(event)}
                                />

                                {this.state.file != null && (
                                    <div className="file_container">
                                        <div className="file_name">
                                            <i className="icons icon-doc" />
                                            <div>{this.state.file.name}</div>
                                        </div>
                                        <i
                                            className="icons icon-close"
                                            onClick={() => this.removeFile()}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {this.state.error && (
                    <AlertBox messages={[translate('errors.empty_field')]} type={'error'} />
                )}

                {this.props.fileError && (
                    <AlertBox messages={[translate('errors.file_is_not_cv')]} type={'error'} />
                )}

                <div className="card--simple__buttons">
                    <div>
                        <Button
                            onClick={() => this.save()}
                            className={
                                'button--alt inline-block' +
                                (this.state.loading && !this.props.stopLoading ? ' is-loading' : '')
                            }
                            buttonText={translate('buttons.continue')}
                        />
                    </div>

                    <Hidden xs>
                        <button onClick={() => this.back()} className="inline-block button--link">
                            <i className="mdi mdi-arrow-left" />
                            <Translate value="onboarding.button_back" />
                        </button>
                    </Hidden>
                </div>
            </div>
        )
    }
}
